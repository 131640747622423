import React from "react";

import InternalPageLayout from "../components/internal-page-layout";
import InternalPageContentCard from "../components/internal-page-content-card";
import Gallery from "../components/gallery";
import commercial1 from "../images/commercial/commercial-2.jpg";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <InternalPageLayout title="Commercial Electrician" crumbs={["Services"]}>
      <SEO
        title="Commercial Electrician in Baltimore County" />
      <InternalPageContentCard>
        <div className="w-full md:w-2/5 lg:w-1/2 mb-6 md:ml-6 md:float-right">
          <img src={commercial1} className="max-w-full" alt="commercial electrician" />
        </div>

        <p className="mb-3">High quality commercial electrical projects require a certain level of expertise to be done well. With 4 Master Electricians on our team and over 30 years of businesses, we can help you with your commercial build-outs and renovations.</p>

        <p className="font-bold">Some of the popular services we offer:</p>

        <ul className="mt-3 list-disc ml-8">
          <li>Office and retail space build-outs and renovations</li>
          <li>LED lighting</li>
          <li>Electrical services</li>
          <li>Outdoor security lighting</li>
          <li>Exit and emergency lighting</li>
          <li>Wiring for special equipment</li>
          <li>Maintenance</li>
          <li>Troubleshooting</li>
          </ul>
      </InternalPageContentCard>
      <InternalPageContentCard>
        <h3 className="text-gray-800 mb-1 font-semibold text-lg">Recent commercial projects</h3>
        <p className="mb-12">Here are a few of our recent commercial projects we've completed.</p>
        <Gallery gallery="commercial" />
      </InternalPageContentCard>
    </InternalPageLayout>
  );
}

export default IndexPage;
